<template>
  <div class="treeContainer">
    <b-row align-content="center">
      <b-col>
        대
        <b-button
          size="sm"
          variant="outline-primary"
          v-b-tooltip.hover
          title="Depth1 순서 저장"
          @click="validateOrdSn(0)"
        >
          <b-icon icon="cloud-upload" variant="primary"></b-icon>
        </b-button>
      </b-col>
      <b-col>
        중
        <b-button
          size="sm"
          variant="outline-primary"
          v-b-tooltip.hover
          title="Depth2 순서 저장"
          @click="validateOrdSn(1)"
        >
          <b-icon icon="cloud-upload" variant="primary"></b-icon>
        </b-button>
      </b-col>
      <b-col>
        소
        <b-button
          size="sm"
          variant="outline-primary"
          v-b-tooltip.hover
          title="Depth3 순서 저장"
          @click="validateOrdSn(2)"
        >
          <b-icon icon="cloud-upload" variant="primary"></b-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="section">
        <div v-for="(item, idx) in treeData" :key="idx" style="menu1style">
          <b-row align-content="center">
            <b-col sm="4" class="col">
              <b-form-input
                :data-casn="item.ca_sn"
                :data-depth="item.menu_depth"
                placeholder="#"
                size="sm"
                class="p-1"
                type="number"
                :id="`ord-id-${item.ca_sn}`"
                :value="item.menu_order"
              />
            </b-col>
            <b-col>
              <div
                @click="selectNode(1, item, 'tree1-' + idx)"
                class="menu tree1"
                :id="'tree1-' + idx"
              >
                {{ '\t' }} {{ item.title }}
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col class="col section">
        <div v-for="(item, idx) in depth2Tree" :key="idx">
          <b-row>
            <b-col sm="4" class="col">
              <b-form-input
                :data-casn="item.ca_sn"
                :data-depth="item.menu_depth"
                placeholder="#"
                size="sm"
                class="p-1"
                type="number"
                :id="`ord-id-${item.ca_sn}`"
                :value="item.menu_order"
              />
            </b-col>
            <b-col>
              <div
                @click="selectNode(2, item, 'tree2-' + idx)"
                class="menu tree2"
                :id="'tree2-' + idx"
              >
                {{ '\t' }} {{ item.title }}
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col class="col">
        <div v-for="(item, idx) in depth3Tree" :key="idx">
          <b-row>
            <b-col sm="4" class="col">
              <b-form-input
                :data-casn="item.ca_sn"
                :data-depth="item.menu_depth"
                placeholder="#"
                size="sm"
                class="p-1"
                type="number"
                :id="`ord-id-${item.ca_sn}`"
                :value="item.menu_order"
              />
            </b-col>
            <b-col>
              <div
                class="menu tree3"
                @click="selectNode(3, item, 'tree3-' + idx)"
                :id="'tree3-' + idx"
              >
                {{ '\t' }} {{ item.title }}
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="text-center" v-if="processing">
          <div class="spinner-border spinner-border-sm" role="status" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'MyTree',
  props: {
    treeData: { required: true, type: Array },

    selectTreeNode: { type: Function },
    updateOrder: { type: Function },
    getParentNode: { type: Function }
  },
  data() {
    return {
      showDepth2: false,
      depth2Tree: [],
      depth3Tree: [],
      ordInputs: [],
      isValidateOrd: true,
      processing: false,
      pDepth2Title: null,
      pDepth3Title: null
    }
  },
  methods: {
    selectNode(depth, item, id) {
      this.selectTreeNode(depth, item)

      if (depth == 1) {
        this.depth2Tree = item.children
        this.depth3Tree = []
        this.clearSelectedAll()
      } else if (depth == 2) {
        this.depth3Tree = item.children
        this.clearSelectedTree2()
      } else {
        //
        this.clearSelectedTree3()
      }

      document.querySelector(`#${id}`).classList.add('selected')
    },
    clearSelectedAll() {
      document.querySelectorAll('.tree1').forEach((item, idx) => {
        item.classList.remove('selected')
      })
      document.querySelectorAll('.tree2').forEach((item, idx) => {
        item.classList.remove('selected')
      })
      document.querySelectorAll('.tree3').forEach((item, idx) => {
        item.classList.remove('selected')
      })
    },
    clearTree1() {
      this.clearSelectedAll()
    },
    clearSelectedTree2() {
      document.querySelectorAll('.tree2').forEach((item, idx) => {
        item.classList.remove('selected')
      })
      document.querySelectorAll('.tree3').forEach((item, idx) => {
        item.classList.remove('selected')
      })
    },
    clearSelectedTree3() {
      document.querySelectorAll('.tree3').forEach((item, idx) => {
        item.classList.remove('selected')
      })
    },
    clearTreeInfo() {
      this.depth2Tree = []
      this.depth3Tree = []
      this.clearSelectedAll()
    },

    validateOrdSn(depth) {
      var tempSet = new Set()
      var tempOrdArray = []

      this.isValidateOrd = true
      const nodes = document.querySelectorAll(`input[data-depth='${depth}']`)
      if (nodes.length == 0) {
        alert('상위 카테고리를 선택하세요')
        return
      }

      document.querySelectorAll(`input[data-depth='${depth}']`).forEach(item => {
        // clear error css
        item.classList.remove('is-invalid')
        console.log(item.value)
        if (!tempSet.has(item.value) && item.value) {
          tempSet.add(item.value)
          tempOrdArray.push({ ca_sn: `${item.dataset.casn}`, menu_order: `${item.value}` })
        } else {
          this.isValidateOrd = false
          item.classList.add('is-invalid')
          tempOrdArray = []
        }
      })

      if (this.isValidateOrd) {
        this.processing = true

        this.updateOrder(tempOrdArray)
        setTimeout(() => {
          this.processing = false
        }, 500)
      } else {
        alert(`데이터 오류`)
      }
    }
  }
}
</script>

<style>
.menu {
  cursor: pointer;
  /* border: 0.1px saddlebrown dashed; */
  padding: 10px;
  /* border-bottom: 1px solid; */
}
.treeContainer {
  /* background-color: beige; */
}
</style>

<style scoped>
.selected {
  background-color: #007bff;
  color: #fff;
}
.col {
  border-bottom: 1px #dee2e6 dashed;
}
.section {
  border-right: 1px #dee2e6 solid;
}
.col input {
  position: absolute;
  top: 0.4rem;
  width: 5rem;
  text-align: right;
  padding-right: 10px;
}
</style>
