<template>
  <div id="CategoryList">
    <title-nav :title="'카테고리 관리'" :nav="'강좌관리 / 카테고리 관리'" />
    <b-container fluid>
      <b-row>
        <b-col cols="9">
          <div class="shadow-lg p-3 m-1 bg-white rounded">
            <my-tree
              :treeData="treeData"
              :selectTreeNode="selectTreeNode"
              :updateOrder="updateOrder"
              :getParentNode="getParentNode"
              ref="myTree"
            />
          </div>
        </b-col>
        <b-col>
          <div class="shadow-lg p-3 mb-1 bg-white rounded">
            <b-tabs content-class="mt-3">
              <b-tab title="추가/삭제">
                <!-- <b-row>
                  <b-col>선택한 카테고리 : {{ this.selectedNodeName }} <br /></b-col>
                </b-row> -->
                <b-row>
                  <b-col>
                    <b-form-group id="fieldset-2" label="선택한 카테고리" label-for="input-2">
                      <b-form-input
                        id="input-2"
                        placeholder="-"
                        v-model="selectedCategory"
                        trim
                        :disabled="true"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <!-- <b-form-group id="fieldset-4" label="선택한 카테고리" label-for="input-4">
                      <b-form-input
                        id="input-4"
                        placeholder="선택한 카테고리 추가"
                        v-model="mCategpry1"
                        trim
                        :disabled="!defaultEditMode"
                      ></b-form-input>
                    </b-form-group> -->
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="fieldset-3"
                      label=" ㄴ 추가할 하위 카테고리"
                      label-for="input-3"
                    >
                      <b-form-input
                        id="input-3"
                        placeholder="하위 카테고리명을 입력하세요"
                        v-model="mCategpry3"
                        trim
                        :disabled="defaultEditMode"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label=" 선택한 카테고리 설명" label-for="input_add_content">
                      <b-form-textarea
                        id="input_add_content"
                        placeholder="카테고리 설명을 입력하세요"
                        v-model="description"
                        trim
                        :disabled="defaultEditMode"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="순서" label-for="input_order_add_content">
                      <b-form-input
                        id="input_order_add_content"
                        placeholder="순서"
                        v-model="ordSn"
                        type="number"
                        min="0"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="강좌번호" label-for="input_lc_no_add_content">
                      <b-form-input
                        id="input_lc_no_add_content"
                        placeholder="강좌번호"
                        v-model="lcNo"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="selectedDepth == 1">
                  <b-col>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="isHidden"
                      name="checkbox-1"
                      value="N"
                      unchecked-value="Y"
                    >
                      '중' 분류 ({{ isHidden == 'Y' ? '숨김' : '노출' }}) 설정
                      <span v-b-tooltip.hover.bottom="'프론트 메뉴에서 노출여부'"
                        ><b-icon icon="question-circle"
                      /></span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row style="margin-top:20px">
                  <b-col>
                    <b-button block @click.prevent="addTreeItem" variant="primary" v-show="isNew"
                      >등록</b-button
                    > </b-col
                  ><b-col> <b-button block @click.prevent="showAlertModal">삭제</b-button> </b-col
                  ><b-col>
                    <b-button block @click.prevent="clearInfo">초기화</b-button>
                  </b-col>
                </b-row>
              </b-tab>
              <!-- 슈종 턉 -->
              <b-tab title="수정">
                <!-- <b-row>
                  <b-col>선택한 카테고리 : {{ this.selectedNodeName }} <br /></b-col>
                </b-row> -->
                <b-row>
                  <b-col
                    ><b-form-group id="fieldset-2" label="수정할 카테고리명" label-for="input-4">
                      <b-form-input
                        id="input-4"
                        placeholder="수정할 카테고리 이름"
                        v-model="editFieldCategory"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form_edit_content"
                      label="설명"
                      label-for="input_edit_content"
                    >
                      <b-form-textarea
                        id="input_edit_content"
                        placeholder="카테고리 설명을 입력하세요"
                        v-model="description"
                        trim
                      ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="순서" label-for="input_order_add_content">
                      <b-form-input
                        id="input_order_add_content"
                        placeholder="순서"
                        v-model="ordSn"
                        type="number"
                        min="0"
                      />
                    </b-form-group>
                    <b-form-group label="강좌번호" label-for="input_lc_no_add_content">
                      <b-form-input
                        id="input_lc_no_add_content"
                        placeholder="강좌번호"
                        v-model="lcNo"
                        type="text"
                      />
                    </b-form-group>
                    <b-form-group v-if="selectedDepth == 1">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="isHidden"
                        name="checkbox-1"
                        value="N"
                        unchecked-value="Y"
                      >
                        '중' 분류 ({{ isHidden == 'Y' ? '숨김' : '노출' }}) 설정
                        <span v-b-tooltip.hover.bottom="'프론트 메뉴에서 노출여부'"
                          ><b-icon icon="question-circle"
                        /></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!--수정 버튼 -->
                <b-row>
                  <b-col>
                    <b-button block @click.prevent="editTreeItem" variant="primary">적용</b-button>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
            <br />
            <!-- selectedNode: {{ this.selectedNode }} -->
            <br />
            선택된 분류명: {{ this.selectedCategory }}
            <br />
            추가할 분류명: {{ this.mCategpry3 }}
          </div>
        </b-col>
      </b-row>
    </b-container>
    <template>
      <b-modal ref="model-delItem" hide-footer title="카테고리 삭제">
        <div class=" text-center">
          <h3>정말 삭제하시겠습니까?</h3>
          <h1><b>동영상도 삭제 됩니다.</b></h1>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="deleteTreeItemY">예</b-button>
        <b-button class="mt-2" variant="outline-warning" block @click="deleteTreeItemN"
          >아니요</b-button
        >
      </b-modal>
    </template>
  </div>
</template>

<script>
// import Search from '../util/Search'
// import { user_list } from '@/api/user'

import {
  getCategory,
  createCategory,
  deleteCategory,
  editCategory,
  updateOrderSN
} from '../../api/lecture'
import MyTree from './MyTree.vue'
export default {
  name: 'Category',
  components: {
    MyTree
    // Search
  },
  watch: {
    treeData: function() {
      // if (this.treeData.length == 0) {
      //   this.disabled2 = false
      //   this.disabled3 = true
      // }
    }
    // selectedCategory: function() {
    // 입력한 카테고리2를 삭제한다면
    // if (!this.selectedCategory) {
    //   this.selectedNodeName = ''
    //   this.selectedNode = ''
    // }
    // }
  },
  data() {
    return {
      mCategpry1: '',
      selectedCategory: '',
      editFieldCategory: '',
      mCategpry3: '',
      description: '',
      selectedDepth: 1,
      selectedNode: null,
      selectedNodeName: '',
      disabled2: true,
      disabled3: true,
      isHidden: 'N',
      treeData: [],
      isFirstDepth: false,
      count: 0,
      ordSn: null,
      defaultEditMode: false,
      isNew: true,
      isClose: 'N',
      lcNo: '00'
    }
  },
  methods: {
    validateNewOrderNumber(depth, number) {
      var rt = true
      document.querySelectorAll(`input[data-depth='${depth}']`).forEach(item => {
        // clear error css
        if (item.menu_order == number) {
          rt = false
          return rt
        }
      })
      return rt
    },
    /**
     * 상위 카테고리 Node
     */
    getParentNode(myId) {
      var pNode = this.treeData.find(value => value.ca_sn == myId)
      //1depth 가 아니면 2depth에서 찾는다
      if (!pNode) {
        for (var i = 0; i < this.treeData.length; i++) {
          for (var j = 0; j < this.treeData[i].children?.length; j++) {
            if (this.treeData[i].children[j].ca_sn == myId) {
              pNode = this.treeData[i].children[j]
              return pNode
            }
          }
        }
      }
      return pNode
    },
    showAlertModal() {
      this.$refs['model-delItem'].show()
      if (this.selectedNode) {
        this.$refs['model-delItem'].show()
      } else {
        alert('삭제할 카테고리를 선택하세요')
      }
    },
    deleteTreeItemY() {
      // deleteTreeItem()
      this.deleteTreeItem()
      this.$refs['model-delItem'].hide()
    },
    deleteTreeItemN() {
      this.$refs['model-delItem'].hide()
    },
    async addTreeItem() {
      var responseData = null
      if (this.mCategpry3) {
        if (!this.selectedNode) {
          //아무것도 없는 경우
          //1dpeth 추가
          if (!this.ordSn) {
            alert('순서를 입력하세요')
            return
          }

          const isValidNumber = this.validateNewOrderNumber(1, this.ordSn)
          if (!isValidNumber) {
            alert('중복된 메뉴 순서')
            return
          }
          const response = await createCategory(
            this.mCategpry3,
            0,
            0,
            this.description,
            this.ordSn,
            this.isHidden,
            this.isClose,
            this.lcNo
          )
          if (response.code === '0000') {
            this.treeData = []
            this.clearInfo()
            responseData = await getCategory()
            this.treeData = responseData.data
          } else {
            alert(response.message)
          }
        } else if (this.selectedNode && this.mCategpry3) {
          //2depth 추가
          const response = await createCategory(
            this.mCategpry3,
            this.selectedNode.menu_depth + 1,
            this.selectedNode.ca_sn,
            this.description,
            this.ordSn,
            'N',
            this.isClose,
            this.lcNo
          )
          if (response.code === '0000') {
            this.treeData = []
            responseData = await getCategory()
            this.treeData = responseData.data

            this.clearInfo()
          } else {
            alert(response.message)
          }
        } else {
          alert('하위 카테고리를 입력해주세요')
        }
      } else {
        alert('상위카테고리를 입력 혹은 선택해주세요')
      }
      this.selectedNodeName = ''
      this.selectedNode = ''
      this.selectedCategory = ''
      this.mCategpry3 = ''
    },
    async deleteTreeItem() {
      var ca_sn = this.selectedNode.ca_sn
      const respData = await deleteCategory(ca_sn)
      if (respData.code === '0000') {
        alert('삭제되었습니다')
        this.clearInfo()
        this.treeData = []
        var resposeData = await getCategory()
        this.treeData = resposeData.data
      } else {
        alert(respData.message)
      }
    },
    async editTreeItem() {
      if (this.selectedNode) {
        if (!this.editFieldCategory) {
          alert('변경할 이름을 입력하세요')
        } else {
          const respData = await editCategory(
            this.selectedNode.ca_sn,
            this.editFieldCategory,
            this.description,
            this.ordSn,
            this.isHidden,
            this.isClose,
            this.lcNo
          )
          console.log(`respData: ${JSON.stringify(respData)}`)
          if (respData.code === '0000') {
            alert('반영되었습니다.')
            this.clearInfo()
            var resposeData = await getCategory()
            this.treeData = []
            this.treeData = resposeData.data
          } else {
            alert(respData.message)
          }
        }
      } else {
        alert('수정할 카테고리를 선택하세요')
      }
    },
    selectTreeNode(depth, node) {
      this.isNew = true
      this.selectedNode = node
      this.selectedDepth = depth
      this.isHidden = node.is_hidden
      this.description = node.contents
      this.isClose = node.is_close
      this.lcNo = node.lc_no
      if (depth == 1) {
        this.selectedNodeName = this.selectedNode.title
        this.mCategpry1 = node.title
        this.selectedCategory = node.title
        this.mCategpry3 = ''
        this.description = node.contents && node.contents.length > 0 ? node.contents : ''
        this.defaultEditMode = false
      } else if (depth == 2) {
        var ttt = this.getParentNode(node.p_ca_sn)
        if (ttt) this.mCategpry1 = ttt.title
        else {
          this.mCategpry1 = node.title
        }
        this.selectedCategory = node.title
        this.mCategpry3 = ''
        this.defaultEditMode = false
      } else {
        // depth3
        var ttt = this.getParentNode(node.p_ca_sn)
        this.mCategpry1 = ttt.title
        this.mCategpry3 = ''
        this.defaultEditMode = true
        this.isNew = false
      }
      this.editFieldCategory = node.title
      console.log(node.title)
      this.ordSn = node.menu_order
    },
    clearInfo() {
      this.selectedNode = ''
      this.selectedNodeName = ''
      this.editFieldCategory = null
      this.mCategpry1 = ''
      this.selectedCategory = ''
      this.mCategpry3 = ''
      this.description = ''
      this.$refs.myTree.clearTreeInfo()
      this.defaultEditMode = false
      this.ordSn = null
      this.isNew = true
    },
    async updateOrder(array) {
      console.log(`updateOrder, `, array)
      const resp = await updateOrderSN(array)
      if (resp.code == '0000') {
        this.treeData = []
        location.href = location.href
        return true
      } else {
        alert('순서업데이트 오류,', resp.message)
        return false
      }
    }
  },
  async created() {
    var resposeData = await getCategory()
    this.treeData = resposeData.data
    console.log(this.treeData[0]['title'])
  },
  mounted() {
    this.count = this.treeData.length + 1
    if (this.count > 0) {
      this.disabled2 = false
      this.disabled3 = true
    } else {
      this.disabled2 = false
      this.disabled3 = true
    }
  }
}
</script>

<style scoped></style>
